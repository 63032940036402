import SplitPaneMode from "./components/split-pane-mode"
export default function SplitPaneModePlugin() {
  return {
    // statePlugins: {
    //   layout: {
    //     actions,
    //     selectors,
    //   }
    // },

    components: {
      SplitPaneMode
    }
  }
}
